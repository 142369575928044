html,
body,
#root {
  height: 100%;
  margin: 0;
  background-color: #eee;
}

main {
  margin: 0;
}

li.react-draggable {
  border-top: 1px solid transparent;
}

.cursor-y {
  cursor: ns-resize;
}

.MuiDialogTitle-root {
  background-color: #eee;
}

/* Turn off the color editor on the color picker */
.compact-picker div.flexbox-fix {
  display: none !important;
}
